<template>
  <div class="phucha-totals" v-if="loaded">
    <div class="phucha-totals__card">
      <div class="phucha-totals__title">{{ $t("piggybank.dashboard.available") }}</div>
      <div class="phucha-totals__amount">{{ $utils.formatMoney(saldo, "€") }}</div>
    </div>
    <div class="phucha-totals__card">
      <div class="phucha-totals__title">{{ $t("piggybank.dashboard.availableSoon") }}</div>
      <div class="phucha-totals__amount">{{ $utils.formatMoney(pendingAmount, "€") }}</div>
    </div>
  </div>

  <div class="phucha-totals" v-else>
    <div class="bbsk" style="width: 250px; height: 70px; border-radius: 8px"></div>
    <div class="bbsk" style="width: 250px; height: 70px; border-radius: 8px"></div>
  </div>
</template>

<script>
export default {
  props: {
    aportaciones: {
      type: Number,
      default: 0,
    },
    saldo: {
      type: Number,
      default: 0,
    },
    pendingAmount: {
      type: Number,
      default: 0,
    },
    loaded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
