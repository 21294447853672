<template>
  <div>
    <section class="hucha-list">
      <div class="hucha-list__head">{{ $t("piggybank.list.title") }}</div>
      <div class="hucha-list__filtros">
        <div class="hucha-filtros">
          <div class="hucha-filtros__item">
            <FilterDate @dateUpdated="updateDateFilter" :label="$t('piggybank.list.dateFilter')" placeholder="Todas" displayInline />
          </div>
          <div class="hucha-filtros__item">
            <FilterSearch @searchUpdated="updateConsole" ico="uil-search" :placeholder="$t('piggybank.list.textFilter')" />
          </div>
        </div>
      </div>
      <div class="hucha-list" v-for="(monthData, index) in filteredContributions" :key="index">
        <div class="hucha-list__title" v-once>{{ sectionTitle(monthData.year, monthData.month, monthData.monthText) }}</div>
        <div class="hucha-list__items">
          <HuchaListadoItem
            v-for="(payment, indexPayment) in monthData.payments"
            :key="indexPayment"
            :aportacion="payment"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import FilterDate from "../../../components/ui/filtros/FilterDate.vue";
import FilterSearch from "../../../components/ui/filtros/FilterSearch.vue";
import HuchaListadoItem from "./HuchaListadoItem.vue";
import { mapGetters } from "vuex";

export default {
  name: "HuchaListado",
  components: {
    HuchaListadoItem,
    FilterDate,
    FilterSearch
  },
  props: {
    contributions: null,
  },
  data() {
    return {
      currentSearchTerm: '',
      currentDateFilter: '',

    };
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    filteredContributions() {
      let filtered = this.contributions;

      if (this.currentSearchTerm) {
        filtered = filtered.map(monthData => {
          let filteredPayments = monthData.payments.filter(payment => {
            return payment.contributorName.toLowerCase().includes(this.currentSearchTerm.toLowerCase());
          });
          return { ...monthData, payments: filteredPayments };
        }).filter(monthData => monthData.payments.length > 0);
      }

      if (this.currentDateFilter) {
        filtered = filtered.map(monthData => {
          let filteredPayments = monthData.payments.filter(payment => {
            let paymentDate = new Date(payment.createdAt);
            let selectedDate = new Date(this.currentDateFilter);
            return paymentDate.toISOString().split('T')[0] === selectedDate.toISOString().split('T')[0];
          });
          return { ...monthData, payments: filteredPayments };
        }).filter(monthData => monthData.payments.length > 0);
      }

      return filtered;
    }
  },
  mounted() {

  },
  methods: {
    sectionTitle(year, month, monthText) {
      const date = new Date();
      const currentMonth = date.getMonth() + 1;
      const currentYear = date.getFullYear();
      if(month == currentMonth && year == currentYear) return this.$t("piggybank.list.currentMonth");
      return `${year} ${monthText}`;
    },
    updateConsole(searchTerm) {
      this.currentSearchTerm = searchTerm;
    },
    updateDateFilter(selectedDate) {
      this.currentDateFilter = selectedDate;
    },
  }
};
</script>

<style lang="scss" scoped></style>
