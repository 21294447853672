<template>
  <main class="phucha">
    <div class="bb-container bb-container--lista">
      <Notification
        tipo="success"
        ico="uil-check"
        v-if="showActivada"
        @close-notification="showActivada = false"
      >
        {{ $t("piggybank.confirmationMessage") }}
      </Notification>

      <Notification tipo="primary" v-if="verifiedStatus == 'unverified' && !showActivada">
        {{ $t("piggybank.verification.noticePart1") }}
        <strong> {{ $t("piggybank.verification.noticePart2") }} </strong>
        {{ $t("piggybank.verification.noticePart3") }}
        <template #link>
          <HuchaTransferirStripe :label="$t('piggybank.verification.cta')" tipo="link" />
        </template>
      </Notification>

      <Notification tipo="primary" v-if="verifiedStatus == ''" simple>
        <strong>
          {{ $t("piggybank.verification.noticeConfirmationPart1") }}
        </strong>
        {{ $t("piggybank.verification.noticeConfirmationPart2") }}
      </Notification>

      <Notification tipo="primary" v-if="hasExternalAccount === false">
        <strong>{{ $t("piggybank.iban.noticePart1") }}</strong>
        {{ $t("piggybank.iban.noticePart2") }}
        <template v-slot:link>
          <HuchaAgregarIban :label="$t('piggybank.iban.enter')" tipo="link" @ibanAdded="handleIbanAdded"
                            @errorAddingIban="handleErrorAddingIban" ref="huchaAgregarIban" />
        </template>
      </Notification>

      <Notification
        tipo="success"
        ico="uil-check"
        v-if="ibanSuccessAdded"
        @close-notification="ibanSuccessAdded = false">
        {{ $t("piggybank.iban.success") }}
      </Notification>

      <Notification tipo="error" v-if="showIbanErrorNotification"
                    @close-notification="showIbanErrorNotification = false">
        {{ errorIbanMessage }}
      </Notification>


      <Notification tipo="primary" v-if="pendingPayments" simple>
        <strong>
          {{ $t("piggybank.pendingPayments") }}
        </strong>
        {{ $t("piggybank.expectedTime") }}
      </Notification>

      <section class="phucha__header">
        <div class="phucha__title">{{ $t("header.myPiggybank") }}</div>
        <div class="phucha__meta">
          <div class="phucha__totals">
            <HuchaTotals :aportaciones="amountTotal" :saldo="payoutAvailable" :pendingAmount="pendingAmount" :loaded="loaded" />
          </div>
          <aside class="phucha__actions">
            <!-- Stripe no conectat  -->
            <HuchaTransferirStripe v-if="verifiedStatus == 'unverified' || !verifiedStatus" :label="$t('piggybank.payout.cta')" />

            <!-- Confirmació Stripe  -->
            <HuchaTransferirStripeOk
              disabled="disabled"
              v-if="verifiedStatus == 'pending'"
              :showModal="showPendingVerificationModal"
              @close="showPendingVerificationModal = false"
            />

            <!-- Transferir a cuenta bancaria -->
            <HuchaTransferirCuenta
              v-if="verifiedStatus == 'verified'"
              :saldo="payoutAvailable"
              :payouts="payouts"
              :hasIBAN="hasExternalAccount"
              @showIban="showIban"
            />
          </aside>
        </div>
      </section>
      <section class="phucha__container">
        <HuchaListadoAportacionesSkeleton v-show="!loaded" />
        <HuchaListadoAportaciones
          v-show="payouts.length > 0"
          :payouts="payouts"
          :accountOwner="user.name"
          v-if="contributions.length > 0"
        />
        <HuchaListado
          :contributions="contributions"
          v-if="contributions.length > 0 && loaded"
        />
        <HuchaVacia v-else />
      </section>
      <!-- modal para informar de cuenta verificada -->
      <modal-dialog
        :show="showVerifiedAccountModal"
        target-class="modal__card--mini"
        @close="showVerifiedAccountModal = false"
      >
        <ModalTemplateVue>
          <template #ico>
            <img src="../../assets/img/ico/ico-color-success.svg" alt="" />
          </template>
          <template #title> Cuenta verificada</template>
          <template #description>
            ¡Enhorabuena! Tu identidad ha sido verificada correctamente y ya puedes transferir dinero desde tu hucha a
            tu cuenta bancaria.
          </template>
          <template #actions>
            <button
              class="button button--primary button--lg button--ico-end button--w350"
              @click="showVerifiedAccountModal = false"
            >
              Entendido
            </button>
          </template>
        </ModalTemplateVue>
      </modal-dialog>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import HuchaTotals from "./components/HuchaTotals.vue";
import HuchaVacia from "./components/HuchaVacia.vue";
import HuchaListado from "./components/HuchaListado.vue";
import HuchaListadoAportaciones from "./components/HuchaListadoAportaciones.vue";
import HuchaListadoAportacionesSkeleton from "./skeleton/HuchaListadoAportacionesSkeleton.vue";
import HuchaTransferirStripe from "./components/HuchaTransferirStripe.vue";
import HuchaTransferirStripeOk from "./components/HuchaTransferirStripeOk.vue";
import HuchaTransferirCuenta from "./components/HuchaTransferirCuenta.vue";
import HuchaAgregarIban from "./components/HuchaAgregarIban.vue";
import Notification from "../../components/ui/Notification.vue";
import Alert from "../../components/ui/Alert.vue";
import ModalDialog from "../../components/ui/ModalDialog.vue";
import ModalTemplateVue from "./components/ModalTemplate.vue";

export default {
  name: "Hucha",
  components: {
    HuchaTotals,
    HuchaVacia,
    HuchaListado,
    HuchaListadoAportaciones,
    HuchaListadoAportacionesSkeleton,
    HuchaTransferirStripe,
    HuchaTransferirStripeOk,
    HuchaTransferirCuenta,
    HuchaAgregarIban,
    Notification,
    Alert,
    "modal-dialog": ModalDialog,
    ModalTemplateVue
  },
  data() {
    return {
      huchaLlena: true,
      showVerificar: false,
      showVerificando: false,
      showActivada: false,
      hasExternalAccount: "",
      verifiedStatus: "undefined",
      payoutAvailable: 0,
      amountTotal: 0,
      pendingAmount: 0,
      accountView: null,
      contributions: [],
      payouts: [],
      loaded: false,
      showPendingVerificationModal: false,
      showVerifiedAccountModal: false,
      pendingPayments: false,
      errorIbanMessage: "",
      showIbanErrorNotification: false,
      ibanSuccessAdded: false
    };
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  methods: {
    closeNotification() {
      this.showActivada = false;
    },
    handleIbanAdded() {
      this.hasExternalAccount = true;
      this.ibanSuccessAdded = true;
      this.showIbanErrorNotification = false;

      const stripeAccountId = this.user.stripeAccountId;
      if (stripeAccountId) {
        this.$store.dispatch("getExternalAccount", {
          stripeAccountId,
        });
      }
    },
    handleErrorAddingIban(errorMessage) {
      this.errorIbanMessage = errorMessage;
      this.showIbanErrorNotification = true;
    },
    async fetchBalance() {
      try {
        //const accountId = this.user.stripeAccountId;
        const accountId = null;
        //patch for user without stripe account that can be found on the app
        if (!accountId) {
          accountId = await this.$store.dispatch("getStripeAccountByUserId", {
            id: this.user.id
          });
          console.log("accountId", accountId)
        }
        //end patch
        const apikey = process.env.STRIPE_HBB_API_KEY;
        let options = {
          headers: {
            "Content-Type": "application/json",
            "x-functions-key": apikey
          }
        };
        const response = await axios.get(
          process.env.STRIPE_HBB_API_URL + `balance/${accountId}`,
          options
        );
        this.payoutAvailable = response.data.payoutAvailable;
        this.amountTotal = response.data.amountTotal;
        this.pendingAmount = response.data.payoutPending;
      } catch (error) {
        console.error("Error fetching balance:", error);
      }
    },
    async fetchView(accountId) {
      let apikey = process.env.STRIPE_HBB_API_KEY;
      let options = {
        headers: {
          "Content-Type": "application/json",
          "x-functions-key": apikey
        }
      };
      let apiUrl = process.env.STRIPE_HBB_API_URL + "/account/view/{accountId}";
      if (this.user.locationCountry == "FR") {
        apiUrl += "?culture=fr-FR";
      }
      try {
        //const accountId = this.user.stripeAccountId;
        const response = await axios.get(
          apiUrl.replace("{accountId}", accountId),
          options
        );
        this.accountView = response.data;
      } catch (error) {
        console.error("Error fetching balance:", error);
      }
    },
    async preparePage() {
      let stripeAccountId = this.user.stripeAccountId;
      if (!this.user.stripeAccountId || this.user.stripeAccountId === "" || this.user.stripeAccountId === null || this.user.stripeAccountId === undefined) {
        var res = await this.$store.dispatch("getStripeAccountByUserId", {
          id: this.user.id
        });
        if (!res.data) await this.$router.push("/mi-hucha/crear");
        else stripeAccountId = res.data;
      }
      //else {
      //   await this.fetchView(stripeAccountId);
      // }
      await this.fetchView(stripeAccountId);

      let vs = await this.$store.dispatch("getVerifiedStatus");

      this.payoutAvailable = this.accountView.balance.payoutAvailable;
      this.amountTotal = this.accountView.balance.amountTotal;
      this.pendingAmount = this.accountView.balance.payoutPending;
      this.contributions = this.accountView.contributions;
      this.payouts = this.accountView.payouts;
      this.pendingPayments = this.accountView.balance.payoutPending > 0;
      this.hasExternalAccount = this.accountView.hasExternalAccount;


      this.verifiedStatus = this.user.verifiedStatus;

      if (this.verifiedStatus === "verified") {
        this.showVerificar = false;
      } else {
        this.showVerificar = true;
      }

      if (this.verifiedStatus === "pending") {
        this.showVerificar = false;
        this.showVerificando = true;
      }

      if (this.verifiedStatus === "unverified" || !this.verifiedStatus) {
        this.showVerificar = true;
      }

      if (this.verifiedStatus)

        if (this.$route.query.success === "true") {
          if (this.verifiedStatus === "pending" || !this.verifiedStatus || this.verifiedStatus === "") {
            this.showPendingVerificationModal = true;
          }
          if (this.verifiedStatus === "verified") {
            this.showVerifiedAccountModal = true;
          }
          const newUrl = this.$route.path;
          history.replaceState({}, "", newUrl);
        }

      if (this.$route.query.huchaActivada === "true") {
        this.showActivada = true;
        this.showVerificar = true;
        this.verifiedStatus = "";

        const newUrl = this.$route.path;
        history.replaceState({}, "", newUrl);
      } else {
        this.showActivada = false;
      }
      this.loaded = true;
    },
    showIban(){
      this.$refs.huchaAgregarIban.initiateProcess();
    }
  },
  async mounted() {
    //per ara no fem res aquí. Consolidar el que hi ha montat amb el preparePage
  },
  beforeRouteEnter(to, from, next) {
    // This will be called before the component is created
    // It has access to `this` once the component is created
    next(vm => {
      // Perform any logic here
      vm.preparePage();
    });
  }
};
</script>

<style lang="scss" scoped></style>
