<template>
  <div class="">
    <header class="mch__form-header">
      <div class="mch__form-title">{{ $t("piggybank.payout.step1Title") }}</div>
      <div class="mch__form-description">{{ $t("piggybank.payout.step1Description", { amount: saldo }) }}</div>
    </header>

    <main class="mch__form-amount">
      <div class="bb-form-fieldset">
        <div class="bb-form-item">
          <div class="bb-form-label">{{ $t("piggybank.payout.step1EnterAmount") }}</div>
          <div class="bb-form-group">
            <div class="bb-form-group__ico">
              <img src="../../../assets/img/ico/ico-euro.svg" />
            </div>
            <input
              type="number"
              name="amount"
              id="amount"
              required="required"
              placeholder="100"
              class="bb-form"
              v-model="amount"
            />
          </div>
          <div class="bb-form-error" v-show="isAmountMore">
            {{ $t("piggybank.payout.step1AmountMore", { amount: saldo }) }}
          </div>
          <div class="bb-form-error" v-show="isAmountLess">
            {{ $t("piggybank.payout.step1AmountLess") }}
          </div>
          <div class="bb-form-info bb-form-info--center">{{ $t("piggybank.payout.step1Interval", { minAmount: minAmount, amount: saldo }) }}</div>
        </div>
      </div>
    </main>

    <footer class="mch__actions">
      <button
        :disabled="isAmountMore || isAmountLess"
        @click="nextStep"
        class="button button--primary button--block button--icoEnd"
      >
        {{ $t("generic.next") }} <i class="uil uil-arrow-right"></i>
      </button>
    </footer>
  </div>
</template>

<script>
export default {
  name: "HuchaTransferirCuentaStep1",
  data() {
    return {
      amount: null,
      minAmount: 1,
    };
  },
  props: {
    saldo: Number
  },
  computed: {
    isAmountMore() {
      if (this.amount === null || this.amount === '') {
        return false;
      }
      const amountNumber = parseFloat(this.amount);
      return amountNumber > this.saldo;
    },
    isAmountLess() {
      if (this.amount === null || this.amount === '') {
        return false;
      }
      const amountNumber = parseFloat(this.amount);
      return isNaN(amountNumber) || amountNumber < this.minAmount ;
    },

  },
  components: {},
  methods: {
    nextStep() {
      const amountNumber = parseFloat(this.amount);
      if (!this.isAmountMore && !this.isAmountLess && !isNaN(amountNumber)) {
        this.$emit("next-step", 2, amountNumber);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
