<template>
  <div class="hli">
    <div class="hli__left">
      <!-- Cambia aportacion.nombre por aportacion.contributorName -->
      <div class="hli__name">{{ aportacion.contributorName }}</div>

      <div class="hli__product" v-if="aportacion.productType !== 'fund'">
        <i class="uil uil-gift"></i>
        <router-link
          class="hli__link"
          :to="aportacion.routeToProduct"
        >
          <mq-layout :mq="['m480', 'm576', 'm768']">
            {{ truncateText(aportacion.productName, 35) }}
          </mq-layout>
          <mq-layout :mq="['m992', 'm1200']">
            {{ truncateText(aportacion.productName, 55) }}
          </mq-layout>
        </router-link>
      </div>

      <div class="hli__product" v-if="aportacion.productType === 'fund'">
        <i class="uil uil-gift"></i>

        <mq-layout :mq="['m480', 'm576', 'm768']">
          {{ truncateText(this.$t("piggybank.list.contributionTo", { name: aportacion.productName }), 35) }}
        </mq-layout>
        <mq-layout :mq="['m992', 'm1200']">
          {{ truncateText(this.$t("piggybank.list.contributionTo", { name: aportacion.productName }), 55) }}
        </mq-layout>
      </div>

    </div>
    <div class="hli__comprar">
      <!-- No hay información de "completado" en el objeto. Deberás ajustar según tus datos -->
      <template v-if="aportacion.completed">
        <div class="badge badge--success badge--sm">{{ $t("piggybank.list.completed") }}</div>
        <div class="link link--underline link--bold">{{ $t("piggybank.list.buy") }}</div>
      </template>
    </div>
    <div class="hli__right">
      <!-- Cambia aportacion.aportacion por aportacion.collectedAmount y aportacion.fecha por aportacion.createdAt -->
      <div class="hli__amount">{{ "+" + $utils.formatMoney(aportacion.collectedAmount) }}</div>
      <time class="hli__date">{{ aportacion.createtAtString }}</time>
    </div>
  </div>
</template>

<script>
export default {
  name: "HuchaListadoItem",
  props: {
    aportacion: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    truncateText(text, maxLength, suffix = "...") {
      if (text.length <= maxLength) {
        return text;
      }

      const visibleLength = maxLength - suffix.length;

      return text.substring(0, visibleLength) + suffix;
    }
  },
};
</script>

<style lang="scss" scoped></style>
